import { EventEmitter } from 'events';

class Wakelock extends EventEmitter {

  request: {cancel: () => void} = null;

  start() {
    if (navigator.getWakeLock) {
      navigator.getWakeLock('screen').then((wl: WakeLockEvent) => {
        this.request = wl.createRequest();
      });
    }
  }

  stop() {
    if (this.request !== null) {
      this.request.cancel();
    }
  }
}

export default new Wakelock();
