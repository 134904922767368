import { EventEmitter } from 'events';

class Random extends EventEmitter {

  acceleration: number = 20;
  speed: number = 0;
  interval: number = null;

  constructor() {
    super();
  }

  start() {
    this.interval = window.setInterval(() => {
      this.acceleration += (0.5 - Math.random()) * 10;
      this.speed += this.acceleration;
      if (this.speed <= 0) {
        this.speed = 0;
        this.acceleration *= -0.9;
      }
      if (this.speed >= 160) {
        this.speed = 160;
        this.acceleration *= -0.9;
      }
      this.emit('speed', Math.round(this.speed));
    }, 1000);
  }

  stop() {
    clearInterval(this.interval);
  }
}

export default new Random();
