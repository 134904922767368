import { EventEmitter } from 'events';
import location from './location';
import motion from './motion';
import random from './random';
import ambient from './ambient';
import wakelock from './wakelock';
import { setValue } from './gauge';

// chrome://flags#enable-generic-sensor-extra-classes
// https://www.chromestatus.com/feature/4636879949398016

import './style.scss';
import './favicon.ico';

interface SpeedSource extends EventEmitter {
  start(): void
  stop(): void
}

const modes: { [key: string]: SpeedSource} = { location, motion, random };

Object.values(modes).forEach((mode: SpeedSource) => {
  mode.on('speed', (speed: number) => {
    setValue(speed);
  });
});

const nightButton = document.querySelector("#mode .night");
nightButton.addEventListener('click', () =>{
  nightButton.classList.toggle('active');
  document.body.classList.toggle('dark');
});

ambient.on('dark', (dark: boolean) => {
  document.body.classList.toggle('dark', dark);
  nightButton.classList.toggle('active', dark);
});

const buttons = document.querySelectorAll("#mode .mode");

const stop = () => {
  Object.values(modes).forEach((mode: SpeedSource) => mode.stop());
  buttons.forEach(button  => button.classList.remove('active'));
  wakelock.stop();
  setValue(0);
};

const start = (mode: SpeedSource) => {
  stop();
  wakelock.start();
  ambient.start();
  mode.start();
};

const toggle = (mode: string, state: boolean) => {
  if (state) {
    stop();
  } else{
    start(modes[mode]);
    document.querySelector(`#mode button[data-mode=${mode}]`).classList.add('active');
  }
};

window.random = () => toggle('random', false);

buttons.forEach((button) => {
  button.addEventListener('click', () => {
    const mode = button.getAttribute('data-mode');
    toggle(mode, button.classList.contains('active'));
  });
});

if (module.hot) {
  module.hot.dispose(() => window.location.reload());
}
