import { EventEmitter } from 'events';

class Motion extends EventEmitter {

  speed: number = 0;
  interval: number;

  constructor() {
    super();
    this.onMotion = this.onMotion.bind(this);
  }

  onMotion(event: DeviceMotionEvent) {
    this.speed = Math.max(
        this.speed,
        Math.abs(event.acceleration.x),
        Math.abs(event.acceleration.y),
        Math.abs(event.acceleration.z)
    );
  }

  start() {
    window.addEventListener('devicemotion', this.onMotion);
    this.interval = window.setInterval(() => {
      this.emit('speed', Math.round(this.speed));
      this.speed = 0;
    }, 1000);
  }

  stop() {
    window.removeEventListener('devicemotion', this.onMotion);
    clearInterval(this.interval);
  }
}

export default new Motion();
