const gaugeBar = document.querySelector('#gauge .bar');
const gaugeText = document.querySelector('#gauge .text');

export function setValue(value: number) {
  gaugeText.textContent = `${value}`;
  const red = Math.round(value * 1.5);
  const len = (value / 160) * 270;
  gaugeBar.setAttribute('stroke', `rgb(${red}, 45, 56)`);
  gaugeBar.setAttribute('stroke-dasharray', `${len}, ${360 - len}`);
}
