import { EventEmitter } from 'events';

class Ambient extends EventEmitter {

  sensor: AmbientLightSensor = null;

  start() {
    if (this.sensor !== null) {
      return;
    }

    if ('AmbientLightSensor' in window) {
      window.navigator.permissions.query({ name: 'ambient-light-sensor' })
        .then((result: {state: string}) => {
          if (result.state === 'denied') {
            return;
          }
          this.sensor = new AmbientLightSensor({frequency: 0.25});
          this.sensor.addEventListener('reading', () => {
            if (this.sensor.illuminance < 3) {
              this.emit('dark', true);
            } else if (this.sensor.illuminance > 3) {
              this.emit('dark', false);
            }
          });
          this.sensor.start();
        });
    }
  }
}

export default new Ambient();
