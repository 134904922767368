import { EventEmitter } from 'events';

class Location extends EventEmitter {

  id: number = null;

  start() {
    const options = {
      enableHighAccuracy: true
    };
    this.id = navigator.geolocation.watchPosition((position) => {
      this.emit('speed', Math.round(position.coords.speed * 3.6));
    }, null, options);
  }

  stop() {
    navigator.geolocation.clearWatch(this.id);
  }
}

export default new Location();
